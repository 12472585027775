$(document).ready(function() {

  /* Frontend */
  anchorGoToHash();

  var swiper = new Swiper(".hero__swiper", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

});
